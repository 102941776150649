.form-register {
  color: white;
  background-color: transparent;
  background-image: linear-gradient(180deg, #E01366 0%, #FF63AF 100%);
}

.slider-container {
  margin-top: 0px;
  position: relative;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
}


.slider-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%!important;
  height: 85vh!important; /* 70% da altura da viewport */
  position: relative; /* Para que as setas fiquem posicionadas corretamente */
}
.start-image {
  /* Adicione seus estilos de alinhamento aqui, por exemplo: */
  margin-left: auto;
}
.slider-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.4rem!important;
  background-color: #db0505; /* Cor da barra de progresso */
  transition: width 1s ease-in-out;
}

.arrow-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.arrow-left,
.arrow-right {
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.arrow-left:hover,
.arrow-right:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.progress-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background: linear-gradient(90deg, #ffbcf2 0%, #ff8bf6 50%, #ff705f 100%);
  border-radius: 10px;
}

/* Estilos CSS */
.home-container {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.title {
  color: white;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pink-button {
  background-color: #E01366;
  color: white;
  border: none;
  padding: 0.8rem 1rem;
  margin: 1rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.floating-button-container {
  font-size: 0.75rem;
  text-align: center;
  position: absolute;
  bottom: 0rem; /* Ajuste isso para a posição vertical desejada */
  left: 50%; /* Isso o centralizará horizontalmente */
  transform: translateX(-50%);
  z-index: 2; /* Para garantir que o botão flutuante esteja acima da imagem */
}

.floating-top-container {
  font-size: 0.75rem;
  text-align: center;
  position: absolute;
  top: 0rem; /* Ajuste isso para a posição vertical desejada */
  left: 50%; /* Isso o centralizará horizontalmente */
  transform: translateX(-50%);
  z-index: 2; /* Para garantir que o botão flutuante esteja acima da imagem */
}
